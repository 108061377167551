import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { Analytics } from '@vercel/analytics/react';


const App = () => {
  const [Nmax, setNmax] = useState(4.0); // Maximum possible GPA
  const [Nmin, setNmin] = useState(2.0); // Minimum GPA to pass
  const [Nt, setNt] = useState(''); // Final GPA
  const [Nd, setNd] = useState(''); // Converted GPA

  const convertGpa = () => {
    // Check if the input is a valid number
    const gpa = parseFloat(Nt);

    if (!isNaN(gpa)) {
      // Calculate GPA using the Bavarian formula
      const converted = ((Nmax - gpa) / (Nmax - Nmin)) * 3 + 1;
      setNd(converted.toFixed(2)); // Round to 2 decimal places
    } else {
      setNd('Invalid GPA');
    }
  };

  // In your React component
  useEffect(() => {
    document.title = 'GPA Conversion - Bavarian Grading System';
  }, []);


  return (
    <div>
      <Helmet>
        <title>GPA Conversion to Bavarian System</title>
        <meta name="description" content="Calculate and convert GPA to the Bavarian grading system." />
        <meta name="keywords" content="GPA, conversion, Bavarian, GPA calculator,Convert GPA to German GPA,GPA to German system,Convert GPA to Bavarian scale,
         Academic GPA conversion to German scale,German GPA conversion formula,University GPA conversion to German Grade" />
      </Helmet>
      <Container maxWidth="sm">
        <Typography sx={{ mt: 4 }} variant="h4" align="center" gutterBottom>
          GPA Conversion to Bavarian System
        </Typography>
        <div className="input-container">
          <TextField
            fullWidth
            label="Enter your Final GPA"
            variant="outlined"
            type="number"
            value={Nt}
            onChange={(e) => setNt(e.target.value)}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label="Enter maximum GPA in your University"
            variant="outlined"
            type="number"
            value={Nmax}
            onChange={(e) => setNmax(e.target.value)}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label="Enter minimum GPA to pass in your University"
            variant="outlined"
            type="number"
            value={Nmin}
            onChange={(e) => setNmin(e.target.value)}
          />
          <Box display="flex" justifyContent="center">
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              onClick={convertGpa}
            >
              Convert
            </Button>
          </Box>
        </div>
        <Typography sx={{ mt: 2 }} variant="h6" align="center">
          Converted GPA (Bavarian System): {Nd}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body2" align="center">
          Maximum GPA: {Nmax}, Minimum GPA to Pass: {Nmin}
        </Typography>
      </Container>
      <Analytics />
    </div>
  );
};

export default App;
